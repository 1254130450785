<template>
  <div class="home">
    <div class="content">
      <p class="subtitle has-text-white">
        Registrati ora! <br> Inserisci i tuoi dati e scopri le sorprese..
      </p>
      <form method="post" action="/thank" name="contact" netlify-honeypot="bot-field" data-netlify="true" @submit="validation">
        <input type="hidden" name="form-name" value="contact" />
        <div class="columns is-mobile">
          <div class="column is-6">
            <div class="control">
              <input class="input is-white" type="text" v-model="form.firstName" placeholder="Nome" name="firstName">
            </div>
          </div>
          <div class="column is-6">
            <div class="control">
              <input class="input is-white" type="text" v-model="form.lastName" placeholder="Cognome" name="lastName">
            </div>
          </div>
        </div>
          <div class="columns is-mobile">
            <div class="column birth">
              <div class="select is-small is-pulled-left is-white">
                <select name="dayOfBirth" v-model="form.dayOfBirth">
                  <option value="" selected>Giorno</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="1">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                  <option value="18">18</option>
                  <option value="19">19</option>
                  <option value="20">20</option>
                  <option value="21">21</option>
                  <option value="22">22</option>
                  <option value="23">23</option>
                  <option value="24">24</option>
                  <option value="25">25</option>
                  <option value="26">26</option>
                  <option value="27">27</option>
                  <option value="28">28</option>
                  <option value="29">29</option>
                  <option value="30">30</option>
                  <option value="31">31</option>
                </select>
              </div>
            </div>
            <div class="column birth">
              <div class="select is-small is-white">
                <select name="monthOfBirth" v-model="form.monthOfBirth">
                  <option value="" selected>Mese</option>
                  <option value="1">Gennaio</option>
                  <option value="2">Febbraio</option>
                  <option value="3">Marzo</option>
                  <option value="4">Aprile</option>
                  <option value="5">Maggio</option>
                  <option value="6">Giugno</option>
                  <option value="7">Luglio</option>
                  <option value="8">Agosto</option>
                  <option value="9">Settembre</option>
                  <option value="10">Ottobre</option>
                  <option value="11">Novembre</option>
                  <option value="12">Dicembre</option>
                </select>
              </div>
            </div>
            <div class="column birth">
              <div class="select is-small is-white">
                <select name="yearOfBirth" v-model="form.yearOfBirth">
                  <option value="" selected>Anno</option>
                  <option value="2005">2005</option>
                  <option value="2004">2004</option>
                  <option value="2003">2003</option>
                  <option value="2002">2002</option>
                  <option value="2001">2001</option>
                  <option value="2000">2000</option>
                  <option value="1999">1999</option>
                  <option value="1998">1998</option>
                  <option value="1997">1997</option>
                  <option value="1996">1996</option>
                  <option value="1995">1995</option>
                  <option value="1994">1994</option>
                  <option value="1993">1993</option>
                  <option value="1992">1992</option>
                  <option value="1991">1991</option>
                  <option value="1990">1990</option>
                  <option value="1989">1989</option>
                  <option value="1988">1988</option>
                  <option value="1987">1987</option>
                  <option value="1986">1986</option>
                  <option value="1985">1985</option>
                  <option value="1984">1984</option>
                  <option value="1983">1983</option>
                  <option value="1982">1982</option>
                  <option value="1981">1981</option>
                  <option value="1980">1980</option>
                  <option value="1979">1979</option>
                  <option value="1978">1978</option>
                  <option value="1977">1977</option>
                  <option value="1976">1976</option>
                  <option value="1975">1975</option>
                  <option value="1974">1974</option>
                  <option value="1973">1973</option>
                  <option value="1972">1972</option>
                  <option value="1971">1971</option>
                  <option value="1970">1970</option>
                  <option value="1969">1969</option>
                  <option value="1968">1968</option>
                  <option value="1967">1967</option>
                  <option value="1966">1966</option>
                  <option value="1965">1965</option>
                  <option value="1964">1964</option>
                  <option value="1963">1963</option>
                  <option value="1962">1962</option>
                  <option value="1961">1961</option>
                  <option value="1960">1960</option>

                </select>
              </div>
            </div>
          </div>
          <div class="columns is-mobile">
            <div class="column">
              <div class="control is-pulled-right">
                <input class="is-checkradio is-small is-white" id="sexRadioInline1" type="radio" checked="checked" name="sex" value="M" v-model="form.sex">
                <label for="sexRadioInline1" class="has-text-grey-lighter">Maschio</label>
              </div>
            </div>
            <div class="column">
              <div class="control is-pulled-left">
                <input class="is-checkradio is-small is-white" id="sexRadioInline2" type="radio" name="sex" value="F" v-model="form.sex">
                <label for="sexRadioInline2" class="has-text-grey-lighter">Femmina</label>
              </div>
            </div>
          </div>
          <div class="columns is-mobile">
            <div class="column is-12">
              <div class="control">
                <input class="input is-white" type="text" placeholder="Telefono" v-model="form.telephone" name="telephone">
              </div>
            </div>
          </div>
          <div class="columns is-mobile">
            <div class="column is-12">
              <div class="control">
                <input class="input is-white" type="text" placeholder="Email" v-model="form.email" name="email">
              </div>
            </div>
          </div>
          <div class="columns is-mobile">
            <div class="column is-6 is-offset-3">
              <div class="control">
                <input class="input is-white fucsia" type="text" placeholder="Ticket n°" v-model="form.ticket" name="ticket">
              </div>
            </div>
          </div>
          <div class="columns is-mobile">
            <div class="column is-12 field">
              <input class="is-checkradio is-small is-white" id="privacyCheckbox" type="checkbox" name="privacy" checked="checked" v-model="form.privacy">
              <label for="privacyCheckbox" class="has-text-white">Accetto al <a class="has-text-grey-light" href="#" @click="isModalActive = true"> trattamento dei dati personali</a></label>
            </div>
          </div>
          <div class="columns is-mobile">
            <div class="column is-12">
              <button class="button is-medium is-dark is-fullwidth is-rounded" type="submit">INVIA</button>
            </div>
        </div>
      </form>
    </div>
    <div class="modal" v-bind:class="{'is-active': isModalActive}">
      <div class="modal-background"></div>
        <div class="modal-content">
        Novapieve srl prima di acquisire i Suoi dati personali per la registrazione, la invita a leggere attentamente l’informativa in tema di protezione dei dati personali. La presente informativa è resa ai sensi dell’art. 13 del Regolamento Europeo n. 679/2016 relativo alla protezione dei dati personali (GDPR).
        Titolare del Trattamento e DPO
        In questa sezione le indichiamo quali sono i nostri riferimenti Novapieve srl, rappresentata dall’Amministratore Delegato pro-tempore, è contattabile tramite posta raccomandata all’indirizzo fisico via Roma47, Pieve a Nievole (PT).
        <br><br>
        <strong>Tipologie di dati personali</strong><br>
        In questa sezione le indichiamo quali tipologie di dati le chiediamo Dati acquisiti direttamente dall’interessato indirizzo mail e password. Dati acquisiti in modo automatico durante la navigazione Sito web: Indirizzo IP, tipo di browser, ISP, sistema operativo e versione. App: Device ID, sistema operativo e versione, log di autenticazione (user ID, di navigazione), log di pagamento, log di finalizzazione con emissione dei titoli, materializzazione dei titoli (ove prevista), geo-localizzazione. I predetti dati saranno trattati con supporti informatici in modo da garantire idonee misure di sicurezza e riservatezza.
        <br><br>
        <strong>Finalità del Trattamento</strong><br>
        In questa sezione le indichiamo lo scopo del trattamento effettuato sui suoi dati Finalità: I dati contrassegnati con (*) nel form (indirizzo mail e password) vengono raccolti allo scopo di consentire la registrazione e la fruizione del servizio. Natura del conferimento: Obbligatoria Base giuridica: Contrattuale Finalità: I dati facoltativi all’interno del form di registrazione, nonché gli ulteriori dati di arricchimento del profilo (data di nascita), sono raccolti per consentire la compilazione automatica dei campi in caso di acquisto di un titolo di viaggio. Natura del conferimento: Obbligatoria Base giuridica: Contrattuale Finalità: indirizzo mail, inclusi i dati acquisiti in modo automatico durante la navigazione, sono acquisiti ai fini della registrazione. Natura del conferimento: Obbligatoria Base giuridica: Contrattuale Il conferimento dei dati necessari al perseguimento delle finalità di cui ai punti a. b. e c. ha natura “obbligatoria” e un suo eventuale rifiuto potrebbe comportare l’impossibilità per Novapieve srl di consentire e garantirLe di essere riconosciuto univocamente in fase di accesso, di navigare l’applicazione, di effettuare ricerche e la partecipazione a contest.
        Soggetti destinatari dei dati
        In questa sezione le indichiamo chi tratterà i suoi dati e a chi verranno comunicati Per il perseguimento delle finalità suddette, i dati personali saranno trattati dai seguenti soggetti: - Novapieve srl -Autorità giudiziarie - Autorità di pubblica sicurezza I suoi dati verranno trattati soltanto da soggetti espressamente autorizzati da Novapieve srl o da aziende che agiscono in qualità di Responsabili del Trattamento, per conto di Novapieve srl, e che hanno sottoscritto un apposito contratto che disciplina puntualmente i trattamenti loro affidati e gli obblighi in materia di protezione dei dati, e non verranno mai diffusi. L'elenco aggiornato dei soggetti destinatari dei dati è disponibile richiedendolo tramite posta raccomanda all’indirizzo fisico Via Roma47, Pieve a Nievole (PT). Con riferimento alla finalità c., i suoi dati verranno trattati da partner commerciali che agiscono in qualità di co-titolari del trattamento, e che hanno sottoscritto un apposito contratto che disciplina puntualmente i trattamenti loro affidati e gli obblighi in materia di protezione dei dati
        <br><br>
        <strong>Conservazione dei dati</strong><br>
        In questa sezione le indichiamo per quanto tempo conserveremo i suoi dati. I dati personali che ci ha fornito saranno conservati per: Finalità il cui conferimento ha natura obbligatoria Fino alla cancellazione dell’account o, in caso di mancato utilizzo, dopo 24 mesi dall’ultimo accesso. I dati acquisiti per conservazione degli Over e per il riscatto del premio fino a 18 mesi dalla data di riscatto del premio.
        Diritti degli Interessati
        In questa sezione le indichiamo quali diritti le garantiamo Il Regolamento UE 2016/679 (artt. da 15 a 23) conferisce agli interessati l’esercizio di specifici diritti. In particolare, in relazione al trattamento dei suoi dati personali, ha diritto di chiedere a Novapieve srl l’accesso, la rettifica, la cancellazione, la limitazione, l’opposizione e la portabilità dei suoi dati personali; lei inoltre ha diritto di proporre reclamo, qualora ritenga che i suoi diritti siano stati violati, nei confronti dell’Autorità di Controllo, che in Italia è il Garante per la Protezione dei Dati Personali. In qualsiasi momento, potrà chiedere di esercitare i suoi diritti a Novapieve srl contattabile tramite posta raccomanda all’indirizzo fisico Via Roma47, Pieve a Nievole (PT) ovvero rivolgendosi al Data Protection Officer.
        </div>
        <button class="modal-close is-large" aria-label="close" @click="isModalActive = false"></button>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      isModalActive: false,
      form: {
        firstName: '',
        lastName: '',
        sex: 'M',
        telephone: '',
        email: '',
        ticket: '',
        dayOfBirth: '',
        monthOfBirth: '',
        yearOfBirth: '',
        privacy: true,
      }
    }
  },
  methods: {
    encode (data) {
      return Object.keys(data)
        .map(
          key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join("&");
    },
    validation (event) {
      event.preventDefault();
      if (this.form.firstName == "" || this.form.lastName == "" || this.form.ticket == "" ||
        this.form.dayOfBirth == "" || this.form.monthOfBirth == "" || this.form.yearOfBirth == "" ||
          this.form.telephone == "" || !this.form.privacy) {
        alert("Compila tutti i campi!")
        return
      }
      const axiosConfig = {
        header: { "Content-Type": "application/x-www-form-urlencoded" }
      };
      this.axios.post(
        "/",
        this.encode({
          "form-name": "contact",
          ...this.form
        }),
        axiosConfig
      ).then(() => {
        this.$router.push('thank')
      });

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.home {
  width: 100%;
  flex: 1 1 auto;
}
.content {
  padding: 30px;
  padding-top: 0px;
}
.title, .subtitle {
  font-size: 1.15rem;
  color: #aeaeae;
}
.image {
  width: 90%;
  margin-top: 15px;
}
.field {
  margin-bottom: 0px !important;
}
input {
  background-color: #dedede;
  box-shadow: none;
}
.column {
  padding: 5px;
  padding-top: 15px;
}
.select, select {
  width: 100%;
  background-color: #dedede !important;
  border: none !important;
  box-shadow: none !important;
}
label {
  font-size: 0.75rem !important;
}
.fucsia {
  border-color: #f3f315 !important;
  background-color: #f3f315 !important;
  color: black;
}
.fucsia::placeholder {
  color: black;
  opacity: 0.7;
}
.modal-background {
  background-color: white;
}
.modal-content {
  text-align: left;
  padding: 30px;
}
.modal-close {
  background-color: black;
}
</style>

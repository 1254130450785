<template>
  <div class="home">
    <div class="content">
      <p class="subtitle">
        Complimenti, la registrazione è avvenuta con successo! <br> Buon divertimento
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Thank'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.home {
  width: 100%;
  flex: 1 1 auto;
}
.content {
  align-items: center;
  justify-content: center;
}
.title, .subtitle {
  font-size: 1.15rem;
  color: white;
  margin-top: 15px;
  padding-left: 35px;
  padding-right: 35px;
}
.image {
  width: 90%;
  margin-top: 15px;
}
.field {
  margin-bottom: 0px !important;
}
input {
  background-color: #dedede;
  box-shadow: none;
}
.column {
  padding: 5px;
  padding-top: 15px;
}
.select, select {
  width: 100%;
  background-color: #dedede !important;
  border: none !important;
  box-shadow: none !important;
}
label {
  font-size: 0.75rem !important;
}

.fucsia {
  border-color: #39ff14 !important;
  background-color: #39ff14 !important;
  color: black;
}
.fucsia::placeholder {
  color: black;
  opacity: 0.7;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}
</style>

<template>
  <div id="app">
    <div class="header has-text-centered">
      <figure class="image is-inline-block has-text-centered">
        <img src="logo.png">
      </figure>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import 'bulma-extensions/dist/js/bulma-extensions.min.js';
import 'bulma-extensions/dist/css/bulma-extensions.min.css';
export default {
  name: 'app',
}
</script>

<style lang="scss">
@import 'bulma';
html, body {
  width: 100%;
  height: 100%;
  background-color: #000;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  height: 100%;
  display: flex;
  flex-flow: column;
}
img {
  padding-top: 5px;
  width: 275px !important;
}
.header {
  background-color: #000000;
}
</style>
